import React from 'react';

/**
 * @typedef {Object} Person
 * @property {String} name - Person's name
 * @property {String} job - Person's job
 */

/**
 * Founders
 * @constant
 * @name founders
 * @memberof AboutView
 * @type {Person[]}
 */ 
export const founders = [{
	name: 'about_founders_01_name',
	job: 'about_founders_01_job'
},{
	name: 'about_founders_02_name',
	job: 'about_founders_02_job'
},{
	name: 'about_founders_03_name',
	job: 'about_founders_03_job'
}];

/** 
 * Display company founder name and job
 * @function
 * @name displayFounder
 * @memberof AboutView
 * @param {Person} founder - Founder's meta data
 * @return {ReactComponent}
 */
export function displayFounder(t, founder, i) {
	return (
		<div key = { i } className = 'about-founder'>
			<h3 className = 'about-founder__name'>{t(founder.name)}</h3>
			<p className = 'about-founder__job'>{t(founder.job)}</p>
		</div>
	)
}
/**
 * Investors
 * @constant
 * @name investors
 * @memberof AboutView
 * @type {string[]}
 */ 
export const investors = [
	'Kima Ventures',
	'David Bizer',
	'Yannis Yahiaoui',
	'Yves Weisselberger',
	'The Family',
	'GFC',
];

/**
 * Investors slider options
 * @constant
 * @name brandsSettings
 * @memberof AboutView
 * @type {Object}
 */ 
export const basicInvestorSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  draggable: false,
  centerMode: true,
  variableWidth: true,
  autoplay: true,
  // speed: 20800,
  speed: 15800,
  autoplaySpeed: 1,
  cssEase: "linear"
};

/** 
 * Display company investor's name
 * @function
 * @name displayInvestor
 * @memberof AboutView
 * @param {string} str - Investor name
 * @return {ReactComponent}
 */
export function displayInvestor(str,i) {
	return (
		<span key = { i } className = 'about-investor'>
			{str}
		</span>
	)
}