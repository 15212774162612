/**
 * Home section
 * @namespace HomeSection
 */

import React from "react";
import AdaptableLink from "../AdaptableLink";
import ReactImage from "../ReactImage";

// Grid layout
const GRID = "col-md-6 col-lg-6";

/**
 * Home page section
 * @class
 * @name HomeSection
 * @memberof HomeSection
 * @param {HomeSection} props
 * @return {ReactComponent}
 */
export default function HomeSection({
  id,
  title,
  subtitle,
  content,
  button,
  img,
  ChildNode,
  gridLeft,
  gridRight,
  className
}) {
  return (
    <section id={id}>
      <div className="section-wrapper">
        <div className="wrapper">
          <div className={`${className} grid-flex grid-flex--center`}>
            {/* Content */}
            <div className={`grid-flex__item ${gridLeft}`}>
              <div className={`${className}__wrapper`}>
                {/* section title */}
                {title ? (
                  <h2 className={`${className}__title home-title`}>{title}</h2>
                ) : null}

                {/* section subtitle */}
                {subtitle ? (
                  <h3 className={`${className}__subtitle`}>{subtitle}</h3>
                ) : null}

                {/* section content */}
                <p className={`${className}__content`}>{content}</p>

                {/* Call to action */}
                {button && (
                  <AdaptableLink
                    className={`${className}__button`}
                    to={button.url}
                    onClick={button.onClick}
                    id={button.id}
                  >
                    <button className="hs-button--submit">{button.text}</button>
                  </AdaptableLink>
                )}
              </div>
            </div>

            {/* Image */}
            <div className={`grid-flex__item ${gridRight}`}>
              {ChildNode ? (
                <ChildNode />
              ) : img ? (
                <ReactImage img={img} className={`${className}__img`} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

HomeSection.defaultProps = {
  gridLeft: GRID,
  gridRight: GRID,
  className: "section-home",
  button: null
};
