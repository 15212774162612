/**
 * Home page
 * @namespace ConfirmationView
 */

import React from "react";
import { Translation } from "react-i18next";

/**
 * Contact page
 * @class
 * @name ConfirmationView
 * @memberof ConfirmationView
 * @return {ReactComponent}
 */
export default function() {
  return (
    <Translation>
      {t => (
        <div className="section-platform__wrapper">
          <h1 className="contact__title home-title confirmation">{t('contact_confirmation_title')}</h1>
          <p className="contact__content">{t('contact_confirmation_content')}</p>
        </div>
      )}
    </Translation>
  );
}
