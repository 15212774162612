/**
 * Global functions
 * @namespace FunctionsIndex
 */
import browserLocale from "browser-locale";
import { useLocation } from "react-router-dom";

/**
 * Add a function to fire when DOM is loaded
 * @function
 * @name onDomContentLoaded
 * @memberof FunctionsIndex
 * @return {function}
 */
export function onDomContentLoaded() {
  /**
   * Set DOMLoaded variable when DOM has loaded
   */
  document.addEventListener("DOMContentLoaded", () => {
    window.DOMLoaded = true;
  });

  /**
   * Add a function to fire when DOM is loaded
   * @name FunctionsIndex~onDomContentLoadedFn
   * @param {DomLoadedCallback} cb - Callback function
   */
  return cb => {
    if (window.DOMLoaded) cb();
    else document.addEventListener("DOMContentLoaded", cb);
  };
  /**
   * Function to call on DOMContentLoaded
   * @callback DomLoadedCallback
   */
}

/**
 * Get locale based on user choice, default to browser lang
 * @function
 * @name getLocale
 * @memberof FunctionsIndex
 * @return {string} Selected / default locale
 */
export function getLocale() {
  if (localStorage && localStorage.getItem("locale")) {
    return localStorage.getItem("locale");
  } else {
    return browserLocale().substr(0, 2);
  }
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
