/**
 * Utils function for react components
 * @namespace FunctionsComponent
 */
import React from 'react';

/**
 * Get query parameter value
 *
 * @function
 * @name getQueryComponent
 * @memberof FunctionsComponent
 * @param {String} name - Name of query parameter
 * @param {*} defaultValue - Default value if parameter is missing
 * @return {String} Value of query parameter
 */
export function getQueryComponent(name, defaultValue) {
	const matchQuery = window.location.search.match(`${name}=([^&]*)`);

	defaultValue = defaultValue || null;

	return matchQuery && matchQuery.length > 0 ? decodeURIComponent(matchQuery[1]) : defaultValue;
}

/** 
 * Update component state
 * @function
 * @name updateState
 * @memberof FunctionsComponent
 * @param {function} setter - state modifier
 * @param {*} currentState - state
 * @return {function} onchange/onclick event handler
 */
export function updateState(setter, currentState) {
	return (evt) => {
		const {name, value} = evt.target

		if ( typeof currentState === 'object') {
			let newState = Object.assign({}, currentState);
			newState[name] = value;

			setter(newState);
			return;
		} else {
			setter(value);
		}
	}
}

/**
 * Replace new lines with <span
 * @function
 * @name nl2br
 * @memberof FunctionsComponent
 * @param {string} str
 * @return {ReactComponent}
 */
export function nl2br(str) {
	// if (str.split(/\n/).length < 2) return str;
	return splitStr(
		str, 
		/\n/, 
		({children}) => {
			return <span>{bTxt(children)}</span>
		})
	
	// str.split(/\n/).map((substr,i) => {
	// 	return <span key={i}>{substr} </span>
	// })
}

function bTxt(str) {
	return splitHalf(
		str, 
		'\b', 
		({children}) => {
			return <b>{children}</b>
		})
}

function splitStr(str, c, Jsx) {
	return str.split(c).map((substr, i) => {
		return <Jsx key = {i}>{ substr }</Jsx>
	})
}

function splitHalf(str, c, Jsx) {
	return str.split(c).map((substr, i) => {
		if ( i % 2 === 0 ) return substr;
		return <Jsx key = {i}>{ substr }</Jsx>
	})
}