/**
 * Fake webapp screens
 * @namespace WebApp
 */

import React from 'react';

/**
 * Fake OS window controls
 * @class
 * @name WindowControls
 * @memberof WebApp
 * @return {ReactComponent}
 */
function WindowControls() {
	return(
		<div className = 'webapp__w-btn'>
			<span className = 'webapp-btn--red'></span>
			<span className = 'webapp-btn--yellow'></span>
			<span className = 'webapp-btn--green'></span>
		</div>
	)
}

/**
 * Fake blue header info
 * @class
 * @name WebAppNav
 * @memberof WebApp
 * @param {Object} props
 * @param {string} [icon=''] - icon css class
 * @param {ReactComponent[]} children - Child nodes
 */
function WebAppNav({icon, children}) {
	return (
		<div className = 'webapp-nav'>
			<span className = { icon }></span>
			<span className = 'webapp-nav__wrapper'>
				{ children }
			</span>
		</div>
	)
}

WebAppNav.defaultProps = {
	icon: ''
}

/**
 * Box for Skills and Profile window
 * @class
 * @name WebAppBox
 * @memberof WebApp
 * @param {Object} props
 * @param {string} title - section title 
 * @param {boolean} noIcon - remove icon menu 
 * @param {ReactComponent[]} title - Child nodes
 * @return {ReactComponent}
 */
export function WebAppBox({ title, children, noIcon, ...args }) {
	return (
		<div className = 'webapp-box' {...args}>
			<span className = 'webapp-icon webapp-icon--menu'></span>
			<div>
				<span className = 'webapp-box__title'>{ title }</span>
				<table><tbody>{ children.map((child, i) => {
					return (
						<tr key = { i } >
							<td>
								<span className = 'webapp-box__content'>
									{child[0]}
								</span>
							</td>
							<td>{child[1]}</td>
						</tr>
					)
				}) }</tbody></table>
			</div>
		</div>
	)
}

/**
 * Box for Skills and Profile window
 * @class
 * @name WebAppBox
 * @memberof WebAppBoxSimple
 * @param {Object} props
 * @param {string} title - section title 
 * @param {ReactComponent[]} title - Child nodes
 * @return {ReactComponent}
 */
export function WebAppBoxSimple({ title, children, ...args }) {
	return (
		<div className = 'webapp-box' {...args}>
			<div>
				<span className = 'webapp-box__title'>{ title }</span>
				<div className = 'webapp-box__table'>
					{children.map((child,i) => {
						return <span key = { i }>{ child }</span>
					})}
				</div>
			</div>
		</div>
	)
}

/**
 * Fake webapp base component
 * @class
 * @name WebApp
 * @memberof WebApp
 * @param {Object} props
 * @param {string} [props.theme=dark] - css theme
 * @param {string} props.headers - fake blue header info
 * @param {ReactComponent[]} props.children - Child nodes
 * @return {ReactComponent}
 */
export default function WebApp({theme, headers, children}) {
	return(
		<div className = 'webapp'>
			<div className = {`webapp__header webapp__header--${theme}`}>
				
				<WindowControls />

				<div className = 'webapp__menu'>
					<span className = 'webapp-icon webapp-icon--search'></span>
					<span className = 'webapp-icon webapp-icon--mail'></span>
				</div>

				{/** Fake header infos */}
				<div className = 'grid-flex grid-flex--row-lg'>{ headers.map((header,i) => {
					return (
						<div key = { i } className = 'grid-flex__item'>
							<WebAppNav icon = {header.icon}>{header.jsx}</WebAppNav>
						</div>
					)
				}) }</div>

			</div>
			<div className = 'webapp__body'>
				{ children }
			</div>
		</div>
	)
}

WebApp.defaultProps = {
	theme: 'dark',
	headers: []
}