import React from 'react';

import {nl2br} from '../functions'

/**
 * Company chronology
 * @class
 * @name CpyHistory
 * @memberof AboutView
 * @return {ReactComponent}
 */
export default function({t}) {
	return(
		<div id = 'Chrono'>
			<div className = 'chrono'>
				
				<span className = 'chrono__el'>
					<span className = 'chrono__date'>2016</span>
					<span className = 'chrono__info chrono__info--top'>
						<span className = 'chrono__handle chrono__handle--mobile-bottom chrono__handle--top chrono__handle--right'></span>
						<span id = 'ChronoTxt01' className = 'chrono__content chrono__content--right chrono__content--bottom'>
							{nl2br(t('about_chrono_01'))}
						</span>
					</span>
				</span>

				<span className = 'chrono__el'>
					<span className = 'chrono__date'>2017</span>
					<span className = 'chrono__info'>
						<span className = 'chrono__handle chrono__handle--mobile-top chrono__handle--bigger chrono__handle--bottom chrono__handle--left'></span>
						<span id = 'ChronoTxt02' className = 'chrono__content chrono__content--left chrono__content--top'>
							{nl2br(t('about_chrono_02'))}
						</span>
					</span>
					<span className = 'chrono__info chrono__info--top'>
						<span className = 'chrono__handle chrono__handle--mobile-top chrono__handle--bigger chrono__handle--top chrono__handle--left'></span>
						<span id = 'ChronoTxt03' className = 'chrono__content chrono__content--bottom'>
							{nl2br(t('about_chrono_03'))}
						</span>
					</span>
				</span>

				<span className = 'chrono__el'>
					<span className = 'chrono__date chrono__date--sep'>2018</span>
					<span className = 'chrono__info'>
						<span className = 'chrono__handle chrono__handle--mobile-top chrono__handle--bigger chrono__handle--bottom chrono__handle--left'></span>
						<span id = 'ChronoTxt04' className = 'chrono__content chrono__content--top'>
							{nl2br(t('about_chrono_04'))}
						</span>
					</span>
					<span className = 'chrono__info chrono__info--top'>
						<span className = 'chrono__handle chrono__handle--mobile-top chrono__handle--bigger chrono__handle--top chrono__handle--left'></span>
						<span id = 'ChronoTxt05' className = 'chrono__content chrono__content--bottom'>
							{nl2br(t('about_chrono_05'))}
						</span>
					</span>
				</span>

				<span className = 'chrono__el'>
					<span className = 'chrono__date chrono__date--sep'>2019</span>
					<span className = 'chrono__info chrono__info--dash'>
						<span className = 'chrono__handle chrono__handle--mobile-top chrono__handle--bottom chrono__handle--left'></span>
						<span id = 'ChronoTxt06' className = 'chrono__content chrono__content--top'>
							{nl2br(t('about_chrono_06'))}
						</span>
					</span>
				</span>

			</div>
		</div>
	);
}