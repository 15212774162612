/**
 * Home page
 * @namespace AboutView
 */

import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { testimonies, testimoniesSettings } from '../config/home/testimonies';
import { kpis } from '../config/about/kpis';
import { 
	founders, 
	displayFounder,
	investors,
	basicInvestorSettings,
	displayInvestor } from '../config/about/founders';
import { 
	values, 
	sliderSettings, 
	displayValue } from '../config/about/values';

import TestimonySlider from '../components/home/TestimonySlider';
import HomeSection from '../components/home/HomeSection';
import Kpi from '../components/home/Kpi';

import CpyHistory from '../components/about/CpyHistory';

import ReactImage from '../components/ReactImage';

import { nl2br } from '../components/functions';

import headerImg from '../assets/img/about/about-illus01.jpg';

import HireIllus04 from '../assets/img/about/Hire-illus04.jpg';
import HireIllus03 from '../assets/img/about/Hire-illus03.jpg';
import HireIllus02 from '../assets/img/about/Hire-illus02.jpg';
import HireIllus01 from '../assets/img/about/Hire-illus01.jpg';

import Founders from '../assets/img/about/founders.jpg';

// split investors in half
let investors1 = investors.slice(0,parseInt(investors.length / 2));
let investors2 = investors.slice(investors1.length);

// investors2 = [investors2.join('  '), ''];
let investors1Settings = Object.assign({}, basicInvestorSettings);
let investors2Settings = Object.assign({}, basicInvestorSettings);

investors2Settings.speed = basicInvestorSettings.speed + 400;

const slider = React.createRef();

/**
 * Page about
 * @class
 * @name AboutView
 * @memberof AboutView
 * @return {ReactComponent}
 */
export default function() {
	const [activeValue,setActiveValue] = useState(0);

	// Fade out slide
	sliderSettings.beforeChange = function(i) {
		setActiveValue(-1);
	}

	// Fade in slide
	sliderSettings.afterChange = function(i) {
		setActiveValue(i);
	}

	useEffect(() => {
		window.scrollTo({top:0});
	},[]);

	return (
		<Translation>
			{
				t => (
					<div id = 'AboutView'>

						<header id = 'AboutHeader' className = 'corner soft-bg'>

							<div className = 'wrapper'>
								<h1 className = 'platform-title push-lg-1 push-md-1'>
									{ nl2br(t('about_title')) }
								</h1>
							</div>

							<HomeSection id = { 'AboutSection01' }
													 title = { nl2br(t('about_section_01_title')) }
													 content = { nl2br(t('about_section_01_content')) }
													 gridLeft = { 'col-md-5 col-lg-5' }
													 gridRight = { 'col-md-7 col-lg-7' }
													 className = 'section-platform'
													 img = { {
													 	src: headerImg,
													 	alt: ''
													 } } />
						</header>

						<section id = 'AboutStory' className = 'soft-bg'>
							<div className = 'wrapper'>
								<h2 className = 'about-title'>
									{t('about_section_02_title')}
								</h2>
								<CpyHistory t = { t } />
							</div>
						</section>

						<section id = 'AboutValues' className = 'soft-bg'>
							<div className = 'about-values'>
								<h3 className = 'about-values__title about-subtitle'>
									{t('about_section_03_title')}
								</h3>

								<div className = 'about-values__slider' ref = { slider }>
									<Slider {...sliderSettings}>
										{values.map(displayValue.bind(null, t, activeValue))}
									</Slider>
								</div>
							</div>
						</section>

						{/* Testomony */}
						<section id = 'HomeTestimony'>
							<TestimonySlider testimonies = { testimonies }
															 settings = { testimoniesSettings } />
						</section>

						{/* KPIs */}
						<section id = 'HomeKpis' className = 'corner-top-right'>
							<div className = 'wrapper'>
								<div className = 'grid'>
									{kpis.map(({title, content}) => {
										return (
											<div key = {title} className = 'grid__item col-md-4 col-lg-4'>
												<Kpi title = { t(title) }
													 	 content = { t(content) }/>
											</div>
										)
									})}
								</div>

								{/* Demo request */}
								<Link to = '/contact' className = 'hs-button--pink'>
									{t('book_demo')}
								</Link>
							</div>
						</section>

						<section id = 'AboutHire' className = 'soft-bg'>

							<div className = 'about-hire-photos'>
								<div className = 'about-hire-photos__wrapper'>
									<div>
										<ReactImage img = {{src: HireIllus01}}
																className = 'about-hire-photos__img'/>
									</div>
									<div>
										<ReactImage img = {{src: HireIllus02}}
																className = 'about-hire-photos__img'/>
										<ReactImage img = {{src: HireIllus03}}
																className = 'about-hire-photos__img'/>
									</div>
									<div>
										<ReactImage img = {{src: HireIllus04}}
																className = 'about-hire-photos__img'/>
									</div>
								</div>
							</div>

							<div className = 'about-hire'>
								<h2 className = 'about-title about-hire__title'>
									{t('about_section_04_title')}
								</h2>
								<p className = 'about-hire__content'>
									{nl2br(t('about_section_04_content'))}
								</p>
								<a href = 'https://www.welcometothejungle.co/fr/companies/hiresweet'
									 className = 'hs-button--pink'
									 target = '_blank'
									 rel = 'noopener noreferrer'>
									{t('about_section_04_button')}
								</a>
							</div>

						</section>

						<section id = 'AboutFounders' className = 'soft-bg'>
							<div className = 'wrapper'>
								<div className = 'grid-flex about-founders'>
									
									<div className = 'grid-flex__item col-lg-5 col-md-5'>
										<h2 className = 'about-title about-founders__title'>
											{nl2br(t('about_section_05_title'))}
										</h2>
										<div className = 'about-founders__content'>
											{founders.map(displayFounder.bind(null,t))}
										</div>
									</div>

									<div className = 'grid-flex__item col-lg-7 col-md-7'>
										<ReactImage className = 'about-founders__img'
																img = { {
																	src: Founders, 
																	alt: 'Ismael, Paul & Robin'
																} } />
									</div>

								</div>
							</div>


							<div className = 'about-investors'>
								<h2 className = 'about-subtitle about-investors__title'>
									{t('about_section_06_title')}
								</h2>

								<div className = 'about-investors__slider'>
									<Slider {...investors1Settings}>
										{investors1.map(displayInvestor)}
									</Slider>
								</div>
								<div className = 'about-investors__slider'>
									<Slider {...investors2Settings}>
										{investors2.map(displayInvestor)}
									</Slider>
								</div>
							</div>

						</section>

					</div>
				)
			}
		</Translation>
	)
}