/**
 * Kpis
 * @constant
 * @name kpis
 * @memberof AboutView
 * @type {KPI[]}
 */ 
export const kpis = [{
	title: 'about_kpi_01_title',
	content: 'about_kpi_01_content'
},{
	title: 'about_kpi_02_title',
	content: 'about_kpi_02_content'
},{
	title: 'about_kpi_03_title',
	content: 'about_kpi_03_content'
}];
