/**
 * Meta data for legalNotice page
 * @namespace LegalNoticeData
 */

/**
 * LegalNotice sections
 * @constant
 * @name legalNoticeSections
 * @memberof LegalNoticeData
 * @type {HomeSection[]}
 */

export const legalNoticeSections = [
  {
    id: "LegalNoticeSection01",
    title: "legal_notice_section_01_title",
    content: "legal_notice_section_01_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection02",
    title: "legal_notice_section_02_title",
    content: "legal_notice_section_02_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection03",
    title: "legal_notice_section_03_title",
    content: "legal_notice_section_03_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection04",
    title: "legal_notice_section_04_title",
    content: "legal_notice_section_04_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection05",
    title: "legal_notice_section_05_title",
    content: "legal_notice_section_05_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection06",
    title: "legal_notice_section_06_title",
    content: "legal_notice_section_06_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection07",
    title: "legal_notice_section_07_title",
    content: "legal_notice_section_07_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection08",
    title: "legal_notice_section_08_title",
    content: "legal_notice_section_08_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection09",
    title: "legal_notice_section_09_title",
    content: "legal_notice_section_09_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection10",
    title: "legal_notice_section_10_title",
    content: "legal_notice_section_10_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection11",
    title: "legal_notice_section_11_title",
    content: "legal_notice_section_11_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "LegalNoticeSection12",
    title: "legal_notice_section_12_title",
    content: "legal_notice_section_12_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  }
];
