import { menu } from './navbar';

/**
 * Footer menu items
 * @constant
 * @name footer
 * @memberof Navigation
 * @type {MenuItem[]}
 */ 
let footer = menu.map((_)=>{
	return Object.assign({},_);
}).slice(0,3);


footer.push({
	name: 'divers',
	children: [{
		name: 'divers_legal',
		url: '/legal-notice'
	},{
		name: 'divers_priv',
		url: '/privacy'
	},{
		name: 'divers_secu',
		url: '/security'
	},]
});

footer.push({
	name: 'social',
	children: [{
		name: 'blog',
		url: 'https://blog.hiresweet.com',
		extern: true,
	},{
		name: 'social_fb',
		url: 'https://www.facebook.com/hiresweet/',
		extern: true,
	},{
		name: 'social_tw',
		url: 'https://twitter.com/hiresweet_',
		extern: true,
	},{
		name: 'social_in',
		url: 'https://www.linkedin.com/company/hiresweet/',
		extern: true,
	},]
});


export { footer };
