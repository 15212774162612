/**
 * Inputs for contact form
 * @memberof ContactForm
 * @constant
 * @name contactInputs
 * @type {Object[]}
 */
export const contactInputs = [
	{
		id: 'contact_email',
		name: 'email',
		type: 'email',
		placeholder: 'contact_email_placeholder',
	},
	{
		id: 'contact_company',
		name: 'company',
		type: 'text',
		placeholder: 'contact_company_placeholder',
	},
	{
		id: 'contact_company_size',
		name: 'company_size',
		type: 'select',
		placeholder: 'contact_company_size_placeholder',
		options: [{
			value: '1-10',
			label:  'contact_company_size_option_1_10',
		},{
			value: '10-49',
			label:  'contact_company_size_option_10_50',
		},{
			value: '50-149',
			label:  'contact_company_size_option_50_150',
		},{
			value: '150-299',
			label:  'contact_company_size_option_150_300',
		},{
			value: '300+',
			label:  'contact_company_size_option_300',
		}]
	},
	{
		id: 'contact_job',
		name: 'job',
		type: 'select',
		placeholder: 'contact_job_placeholder',
		options: [{
			value: 'c-level',
			label: 'contact_job_option__c_level',
		},{
			value: 'talent_acquisition_manager',
			label: 'contact_job_option__talent_acquisition_manager',
		},{
			value: 'lead_tech',
			label: 'contact_job_option__lead_tech',
		},{
			value: 'sales_manager',
			label: 'contact_job_option__sales_manager',
		},{
			value: 'operations_manager',
			label: 'contact_job_option__operations_manager',
		},{
			value: 'marketing_manager',
			label: 'contact_job_option__marketing_manager',
		},{
			value: 'other',
			label: 'contact_job_option__other',
		},],
	},
	{
		id: 'contact_ref',
		name: 'ref',
		type: 'select',
		placeholder: 'contact_ref_placeholder',
		options: [{
			value: 'social_media',
			label:  'contact_ref_option__social_media',
		},{
			value: 'google',
			label:  'contact_ref_option__google',
		},{
			value: 'event',
			label:  'contact_ref_option__event',
		},{
			value: 'press',
			label:  'contact_ref_option__press',
		},{
			value: 'referral',
			label:  'contact_ref_option__referral',
		},{
			value: 'other',
			label:  'contact_ref_option__other',
		}]
	},
	{
		id: 'contact_message',
		name: 'message',
		type: 'textarea',
		placeholder: 'contact_message_placeholder',
	},
	{
		id: 'contact_submit',
		type: 'submit',
		placeholder: 'contact_submit_placeholder',
	}
];

/**
 * Inputs for login form
 * @memberof ContactForm
 * @constant
 * @name loginInputs
 * @type {Object[]}
 */
export const loginInputs = [
	{
		id: 'login_email',
		name: 'email',
		type: 'email',
		placeholder: 'login_email_placeholder',
	},
	{
		id: 'login_password',
		name: 'password',
		type: 'password',
		placeholder: 'login_password_placeholder',
	},
	{
		id: 'login_submit',
		type: 'submit',
		placeholder: 'login_submit_placeholder',
	}
];

