/**
 * Home page
 * @namespace PlatformView
 */

import React, { useEffect } from "react";
import { Translation } from "react-i18next";
import _ from "underscore";

import HomeSection from "../components/home/HomeSection";

import { nl2br } from "../components/functions";

/**
 * Page platform
 * @class
 * @name PlatformView
 * @memberof PlatformView
 * @return {ReactComponent}
 */
const View = ({ sections, title }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <Translation>
      {t => (
        <div id="SecurityView" className="corner">
          <header id="SecurityHeader">
            <div className="wrapper">
              <h1 className="security-title push-lg-1 push-md-1">
                {nl2br(t(title))}
              </h1>
            </div>
          </header>
          <div className="soft-bg">
            {_.map(sections, section => {
              const title = t(section.title);
              const content = nl2br(t(section.content));
              return (
                <HomeSection
                  key={section.id}
                  className="section-security"
                  title={title}
                  content={content}
                  id={section.id}
                  subtitle={section.subtitle}
                  button={section.button} 
                  img={section.img}
                  ChildNode={section.ChildNode}
                  gridLeft={section.gridLeft}
                  gridRight={section.gridRight}
                />
              );
            })}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default View;
