/**
 * Meta data for platform page
 * @namespace PlatformData
 */

import img03 from '../../assets/img/platform/Section03-illus.png';
import { 
	ProfileScreen,
	SkillAndProfile, 
	FollowUp, 
	Metrics 
} from '../../components/platform/AppScreens';

/**
 * Platform sections 
 * @constant
 * @name platformSections
 * @memberof PlatformData
 * @type {HomeSection[]}
 */ 
export const platformSections = [{
	id: 'PlatformSection01',
	title: 'platform_section_01_title',
	content: 'platform_section_01_content',
	gridLeft: 'col-md-5 col-lg-5',
	gridRight: 'col-md-5 col-lg-5 pull-lg-1 pull-md-1',
	ChildNode: SkillAndProfile,
},{
	id: 'PlatformSection02',
	title: 'platform_section_02_title',
	content: 'platform_section_02_content',
	gridLeft: 'col-md-6 col-lg-6',
	gridRight: 'col-md-5 push-md-1 col-lg-5 push-lg-1',
	ChildNode: ProfileScreen
},{
	id: 'PlatformSection03',
	title: 'platform_section_03_title',
	content: 'platform_section_03_content',
	gridLeft: 'col-md-7 col-lg-7',
	gridRight: 'col-md-5 col-lg-5',
	button: {
		url: '/contact',
		text: 'book_demo'
	},
	img: {
		src: img03,
		alt: ''
	}
},{
	id: 'PlatformSection04',
	title: 'platform_section_04_title',
	content: 'platform_section_04_content',
	ChildNode: FollowUp
},{
	id: 'PlatformSection05',
	title: 'platform_section_05_title',
	content: 'platform_section_05_content',
	gridLeft: 'col-md-6 col-lg-6',
	gridRight: 'col-md-5 col-lg-5 pull-lg-1 pull-md-1',
	button: {
		url: '/contact',
		text: 'book_demo'
	},
	ChildNode: Metrics
}];