import img01 from '../../assets/img/technology/electro-cardio.svg';
import img02 from '../../assets/img/technology/cadenas.svg';
import img03 from '../../assets/img/technology/algorithm.svg';
// import img04 from '../../assets/img/technology/schema.svg';
import img04 from '../../assets/img/technology/schema.png';
import img06 from '../../assets/img/technology/man.svg';
import img07 from '../../assets/img/technology/web.svg';


import { 
	FullStack,
} from '../../components/platform/AppScreens';

/**
 * Platform sections 
 * @constant
 * @name technologySections
 * @memberof TechnologyView
 * @type {HomeSection[]}
 */ 
export const technologySections = [{
	id: 'TechnologySection01',
	title: 'technology_section_01_title',
	content: 'technology_section_01_content',
	gridLeft: 'col-md-5 col-lg-5',
	gridRight: 'col-md-6 col-lg-6 pull-lg-1 pull-md-1',
	img: {
		src: img07,
		alt: 'technology'
	}
},{
	id: 'TechnologySection02',
	title: 'technology_section_02_title',
	content: 'technology_section_02_content',
	gridLeft: 'col-md-7 col-lg-7',
	gridRight: 'col-md-5 col-lg-5',
	img: {
		src: img06,
		alt: 'technology'
	}
},{
	id: 'TechnologySection03',
	title: 'technology_section_03_title',
	content: 'technology_section_03_content',
	gridLeft: 'col-md-6 col-lg-6',
	gridRight: 'col-md-5 col-lg-5 pull-lg-1 pull-md-1',
	ChildNode: FullStack,
	button: {
		text: 'book_demo',
		url: '/contact'
	}
},{
	id: 'TechnologySection04',
	title: 'technology_section_04_title',
	content: 'technology_section_04_content',
	img: {
		src: img04,
		alt: 'technology'
	}
},{
	id: 'TechnologySection05',
	title: 'technology_section_05_title',
	content: 'technology_section_05_content',
	gridLeft: 'col-md-6 col-lg-6',
	gridRight: 'col-md-5 col-lg-5 pull-lg-1 pull-md-1',
	img: {
		src: img03,
		alt: 'technology'
	},
	button: {
		text: 'book_demo',
		url: '/contact'
	}
},{
	id: 'TechnologySection06',
	title: 'technology_section_06_title',
	content: 'technology_section_06_content',
	gridLeft: 'col-md-6 col-lg-6 push-md-1 push-lg-1',
	gridRight: 'col-md-4 col-lg-4 push-lg-2 push-md-2',
	img: {
		src: img02,
		alt: 'technology'
	}
},{
	id: 'TechnologySection07',
	title: 'technology_section_07_title',
	content: 'technology_section_07_content',
	gridLeft: 'col-md-6 col-lg-6',
	gridRight: 'col-md-5 col-lg-5 pull-lg-1 pull-md-1',
	img: {
		src: img01,
		alt: 'technology'
	},
	button: {
		text: 'book_demo',
		url: '/contact'
	}
}];