import React from 'react';

/**
 * @typedef {Object} Value
 * @property {String} title
 * @property {String} content
 */

/**
 * Company values
 * @constant
 * @name values
 * @memberof AboutView
 * @type {Value[]}
 */ 
export const values = [{
	title: 'about_values_01_title',
	content: 'about_values_01_content'
},{
	title: 'about_values_02_title',
	content: 'about_values_02_content'
},{
	title: 'about_values_03_title',
	content: 'about_values_03_content'
}];


/**
 * Values slider options
 * @variable
 * @name sliderSettings
 * @memberof AboutView
 * @type {Object}
 */ 
export let sliderSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  draggable: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  cssEase: "linear",
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true
};


/** 
 * Display value slide
 * @function
 * @name displayValue
 * @memberof AboutView
 * @param {number} active - active slide index
 * @param {Value} value - value meta data 
 * @return {ReactComponent} 
 */
export function displayValue(t,active,value, i) {
	return (
		<div key = { i } className = {`about-value ${active===i?'active':''}`}>
			<h4 className = 'about-value__title'>{t(value.title)}</h4>
			<hr className = 'hr-sep' />
			<p className = 'about-value__content'>{t(value.content)}</p>
		</div>
	)
}