/**
 * Language selector
 * @namespace LanguageSelection
 */
import React, {useState} from 'react';
import { Translation } from 'react-i18next';
import { LangContext } from '../config/languages/i18n';

/**
 * Change language on user select
 * @memberof LanguageSelection
 * @param {function} fn - Language state modifier
 * @param {string} valye - Language 
 * @return {function} onclick event handler
 */
function handleLangChange(fn,value) {
	return (evt) => {
		fn(value, () => {
			if (window.DEBUG) 
				console.log('DEBUG: handleLangChange', value);
			localStorage.setItem('locale', value);
		});
	}
}

/**
 * Open / Close lang selection
 * @memberof LanguageSelection
 * @param {function} fn - state modifer
 * @param {*} state - current state
 * @return {function} onclick event handler
 */
function toggleSelect(fn,state) {
	return (evt) => {
		fn(!state);
	}
}

/**
 * Language selection component paired with i18n and react-i18next
 * @class
 * @name LanguageSelection
 * @memberof LanguageSelection
 * @return {ReactComponent} Language selector
 */
export default function() {
	const [isOpen, setOpen] = useState(false);

	let className = isOpen ? ' open' : '';
	return (
		<Translation>
			{t => (
				<LangContext.Consumer>
				  {/**
				    * @param {Object} i18n - Internationalization module
				    */}
				  {props => (

				  	<div className = {`language-select${className}`} 
				  			 onClick = { toggleSelect(setOpen, isOpen) }>
				  		<span>{ t(props.language) }</span>
					  	<div className = 'language-select__options'>
						  	{props.languages && Array.isArray(props.languages) ? 
						  		props.languages.filter((lang) => {
						  			return lang !== props.language
						  		}).map((lang) => {
							  		return (
							  			<div key = { lang } 
							  					 className = 'language-select__option'
							  					 onClick = { 
							  					 	handleLangChange(
							  					 		props.changeLanguage.bind(props),
							  					 		lang
							  					 	)}>
							  				{ t(lang) }
							  			</div>
							  		)
							  	}) : null}
					  	</div>
				  	</div>
				  )}
				</LangContext.Consumer>
			)}
		</Translation>
	)
}