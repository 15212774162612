/* eslint-disable */
/**
 * Image slider for MainView header
 * @namespace SliderBg
 */
import React, { useState, useEffect } from 'react';

import ReactImage from '../ReactImage';

import bg01 from '../../assets/img/home/Banner.jpg';
import bg02 from '../../assets/img/home/Banner02.jpg';
import bg03 from '../../assets/img/home/Banner03.jpg';
import bg04 from '../../assets/img/home/Banner04.jpg';
import bg05 from '../../assets/img/home/Banner05.jpg';

/**
 * Slides
 * @constant
 * @name headerSlider
 * @memberof SliderBg
 * @type {Image[]}
 */
const headerSlider = [{
	src: bg01,
	alt: 'Hire faster better stronger'
},{
	src: bg02,
	alt: 'Hire faster better stronger'
},{
	src: bg03,
	alt: 'Hire faster better stronger'
},{
	src: bg04,
	alt: 'Hire faster better stronger'
},{
	src: bg05,
	alt: 'Hire faster better stronger'
}];

/**
 * Delay between slide change
 * @constant
 * @name DELAY
 * @memberof SliderBg
 * @type {number}
 */
const DELAY = 3300;

/**
 * Header background
 * @class
 * @name SliderBg
 * @memberof SliderBg
 * @return {ReactComponent}
 */
export default function SliderBg({children}) {
	const [activeImg, setActive] = useState(0);
	const [loaded, setLoaded] = useState(false);

	// Trigger animation when mounted
	useEffect(() => {
		setLoaded(true);
	},[])

	/**
	 * Auto change image in a loop
	 * @function
	 * @memberof SliderBg
	 * @name nextSlide
	 */
	useEffect(() => {
		let currentActiveImg = activeImg;
		let interval = setInterval(() => {
			let nextImg = (currentActiveImg + 1) % headerSlider.length;
			currentActiveImg = nextImg;
			setActive(nextImg); 
		}, DELAY);

		// Clear interval when component unmount
		return () => {
			clearInterval(interval);
		}
	}, []);

	return(
		<div className = {`slider-bg-wrapper${loaded?' loaded':''}`}>
			<div className = 'slider-bg-wrapper__title'>
				{children}
			</div>
			<div className = {`slider-bg`}>
				{/**
					* Display background image
					* @function
					* @param {Image} img - image
					* @param {number} i - index
					* @return {ReactImage}
					*/}
				{headerSlider.map((img, i) => {
					return <ReactImage img = { img } 
						key = { i }
						className = {
							`slider-bg__img${i===activeImg ? ' active':''}`
						} />
				})}
			</div>
		</div>
	);
}
