/* eslint-disable */
/**
 * @namespace ContactForm
 */
import React, { useState, Suspense } from "react";
import axios from "axios";
import _ from "underscore";

import { updateState } from "./functions";
import ConfirmationView from "../views/ConfirmationView";
import { freeTrialUrl } from "../config/home/freeTrial";

// Lazy load
const Select = React.lazy(() => import("react-select"));

const hideStyle = () => ({ display: "none" });

/**
 * Custom style for select module
 * @memberof ContactForm
 * @constant
 * @name customStyles
 * @type {Object}
 */
const customStyles = {
  loadingIndicator: hideStyle,
  loadingMessage: hideStyle,
  indicatorsContainer: hideStyle,
  indicatorSeparator: hideStyle,
  dropdownIndicator: hideStyle,
  clearIndicator: hideStyle,
  option: (provided, state) => {
    return {
      fontSize: "16px",
      letterSpacing: ".94px",
      fontFamily: "Gilroy",
      lineHeight: "52px",
      fontWeight: "500",
      height: "52px",
      backgroundColor: "#fff",
      color: "#1F2E77",
      paddingLeft: "26px",
      paddingRight: "8px",
      borderBottom: "1px solid",
      width: "100%"
    };
  },
  valueContainer: (provided, state) => {
    return { ...provided, padding: "0" };
  },
  container: provided => {
    return {
      ...provided,
      cursor: "pointer",
      boxShadow: "0 1px 9px 0 #10173D"
    };
  },
  control: () => {
    return {
      margin: "0 0 5px",
      padding: "0"
    };
  },
  input: provided => {
    console.log(provided);
    return { width: "0px" };
  },
  singleValue: (provided, state) => {
    return {
      fontSize: "16px",
      letterSpacing: ".94px",
      fontFamily: "Gilroy",
      lineHeight: "52px",
      fontWeight: "500",
      height: "52px",
      color: "#1F2E77",
      paddingLeft: "26px",
      paddingRight: "26px",
      borderBottom: "1px solid",
      width: "100%"
    };
  },
  placeholder: () => {
    return {
      fontSize: "16px",
      letterSpacing: ".94px",
      fontFamily: "Gilroy",
      lineHeight: "52px",
      fontWeight: "500",
      height: "52px",
      color: "rgba(31, 46, 119, .5)",
      paddingLeft: "26px",
      paddingRight: "26px",
      width: "100%"
    };
  }
};

/**
 * Hiresweet backend url for landing
 * @memberof ContactForm
 * @constant
 * @name BACK_URL
 * @type {string}
 */
const BACK_URL = "https://app-back.hiresweet.com/landing";
/**
 * Slack hooks url
 * @memberof ContactForm
 * @constant
 * @name SLACK_HOOK
 * @type {string}
 */
const SLACK_HOOK =
  "https://hooks.slack.com/services/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";
/**
 * Google script url
 * @memberof ContactForm
 * @constant
 * @name GOOGLE_SCRIPT
 * @type {string}
 */
const GOOGLE_SCRIPT =
  "https://script.google.com/macros/s/AKfycbzbsJMfIp8AFPoean9ovpsnu9cBydgY9FjaXrB0zsEM06L1EN-a/exec";

// Custom headers for slack
const slackHeadersReq = {
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      delete headers.post["Content-Type"];
      return data;
    }
  ]
};

export const onClickFreeTrialButton = async (e) => {
  e.preventDefault();
  const targetId = e.currentTarget.id;
  const url = 'https://hooks.slack.com/services/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';
  const body = {
    text: `New click on landing free-trial button${targetId ? ' ' + targetId : ''}`,
  };
  try {
    await axios.post(url, JSON.stringify(body), slackHeadersReq);
    window.location = freeTrialUrl;
  } catch (e) {
    console.log('Failed to send free-trial alert to Slack:');
    console.log(e);
  }
};
/**
 * Send email to services : backend, Google, Slack
 *
 * @function SendMail
 * @memberof ContactForm
 * @todo Implement proper error handling
 * @param {string} email - User email
 * @param {*} data - any data
 */
export function SendMail({ email, data }) {
  // Google data
  const googleScriptPostData = {
    date: new Date(),
    email,
    offerLink: "",
    jobDescription: JSON.stringify(data, null, 4)
  };

  // Slack options & data
  const otherInfos = _.compact([
    data.email ? data.email : null,
    data.job ? data.job : null,
    data.company ? `Company: ${data.company}` : null,
    data.companySize ? `Size: ${data.companySize}` : null,
    data.source ? `Source: ${data.source}` : null
  ]);

  const slackMessageFields = [
    {
      title: "by",
      value: otherInfos.join(" | "),
      short: false
    }
  ];

  if (data.comment) {
    slackMessageFields.push({
      title: "Message",
      value: data.comment,
      short: false
    });
  }
  // Final slack data
  const slackPostData = {
    attachments: [
      {
        fallback: "New inbound message on the landing page",
        pretext: "New inbound message on the landing page",
        color: "good",
        fields: slackMessageFields
      }
    ]
  };

  // Send request to back
  // axios
  //   .post(BACK_URL, {
  //     date: new Date(),
  //     message: data
  //   })
  //   .then(response => {
  //     if (window.DEBUG)
  //       console.log("DEBUG: SendMail Back response", response.data);
  //   })
  //   .catch(error => {
  //     if (window.DEBUG) console.log("DEBUG: SendMail Back error", error);
  //   });

  // see https://stackoverflow.com/questions/41042786/cors-issue-using-axios-with-slack-api/49248026
  // Send request to Slack
  axios
    .post(SLACK_HOOK, JSON.stringify(slackPostData), slackHeadersReq)
    .then(response => {
      if (window.DEBUG)
        console.log("DEBUG: SendMail Slack response", response.data);
    })
    .catch(error => {
      if (window.DEBUG) console.log("DEBUG: SendMail Slack error", error);
    });

  // Send request to google
  // axios
  //   .post(GOOGLE_SCRIPT, googleScriptPostData)
  //   .then(response => {
  //     if (window.DEBUG)
  //       console.log("DEBUG: SendMail Google Script response", response.data);
  //   })
  //   .catch(error => {
  //     if (window.DEBUG)
  //       console.log("DEBUG: SendMail Google Script error", error);
  //   });

  // NEED PROPER ERROR HANDLING
  try {
    window.fbq("track", "Lead");
  } catch (e) {}
}

/**
 * Handle form submit
 * @function handleSubmit
 * @memberof ContactForm
 * @param {string} email - User email
 * @param {function} setSubmitted - state modifier
 * @return {function} onsubmit event handler
 */
function handleSubmit(formData, setSubmitted) {
  return evt => {
    evt.preventDefault();
    const email = formData.email;
    const data = {
      step: 0, // TODO : 1 ?
      ...(formData.email && { email: formData.email }),
      ...(formData.job && { job: formData.job }),
      ...(formData.company && { company: formData.company }),
      ...(formData.company_size && { companySize: formData.company_size }),
      ...((formData.ref || formData.ref === 0) && { source: formData.ref }),
      ...(formData.message && { comment: formData.message })
    };
    setSubmitted && setSubmitted(true);
    // Send mail & notify user
    SendMail({ email, data });
  };
}

/**
 * Display contact form
 * @class
 * @name ContactForm
 * @memberof ContactForm
 * @param {Object} props
 * @param {String} props.id - Unique id
 * @param {String} props.placeholder - Email input placeholder
 * @param {String} props.submit - Submit text
 * @param {function} props.onSubmit - callback to replace default submit hanlder
 * @return {ReactComponent}
 */
function ContactForm({ id, placeholder, submit, onSubmit }) {
  const [email, setEmail] = useState("");
  return (
    <form
      id={id}
      className="contact-form"
      onSubmit={onSubmit ? onSubmit(email, setEmail) : handleSubmit({ email })}
    >
      <div className="contact-form__input">
        <input
          name="email"
          type="email"
          id={`${id}-email`}
          value={email}
          placeholder={placeholder}
          onChange={updateState(setEmail, email)}
        />
      </div>
      <div className="contact-form__submit">
        <button type="submit" className="hs-button--submit">
          {submit}
        </button>
      </div>
    </form>
  );
}

ContactForm.defaultProps = {
  id: "ContactForm",
  placeholder: "Email address",
  submit: "Contact me"
};

/**
 * @typedef Input
 * @property {String} id - unique id
 * @property {String} placeholder - Placeholder
 * @property {String} label - Label if different from placeholder
 * @property {String} type - Input type attribute
 * @property {String} name - Input name attribute
 * @property {String} value - Input default value
 * @property {Object[]} options - Options for select
 */

/**
 * Display input
 * @class
 * @name Input
 * @memberof ContactForm
 * @param {Input} input - Input meta data
 * @param {function} onChange - onchange event handler
 * @param {*} setter - State modifier
 */
function Input({ id, name, type, placeholder, options, onChange, value }) {
  switch (type) {
    case "password":
    case "text":
    case "email":
      return (
        <div className="hs-input">
          <input
            type={type}
            name={name}
            id={id}
            value={value ? value : ""}
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      );
    case "textarea":
      return (
        <div className="hs-input hs-input--textarea">
          <textarea
            name={name}
            id={id}
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      );
    case "select":
      return (
        <Suspense
          fallback={<div className="hs-input hs-input--select">Loading...</div>}
        >
          <div className="hs-input--select">
            <Select
              options={options}
              onChange={(selectedOption, field) => {
                onChange({
                  target: {
                    value: selectedOption.value,
                    name: field.name
                  }
                });
              }}
              isSearchable={false}
              styles={customStyles}
              placeholder={placeholder}
              name={name}
              id={id}
            />
          </div>
        </Suspense>
      );
    case "submit":
      return (
        <button type={type} className="hs-input hs-input--submit">
          {placeholder}
        </button>
      );
  }
}

/**
 * Display full contact form
 * @class
 * @name FullContactForm
 * @memberof ContactForm
 * @param {Object} props
 * @param {String} props.id - Unique id
 * @param {Input[]} props.inputs - Form inputs
 * @return {ReactComponent}
 */
export function FullContactForm({ inputs, id, onSubmit, className }) {
  const emailInput = _.findWhere(inputs, { name: "email" });
  const [state, updater] = useState({
    ...((emailInput || {}).value && { email: (emailInput || {}).value })
  });
  const [submitted, setSubmitted] = useState(false);
  let onSubmitFunction;
  if (onSubmit) {
    onSubmitFunction = state => {
      return onSubmit(state, setSubmitted);
    };
  } else {
    onSubmitFunction = state => {
      return handleSubmit(state, setSubmitted);
    };
  }

  if (submitted) {
    return <ConfirmationView />;
  }

  return (
    <form id={id} className={className} onSubmit={onSubmitFunction(state)}>
      {inputs.map(input => {
        return (
          <Input
            key={input.id}
            onChange={updateState(updater, state)}
            value={state[input.name]}
            {...input}
          />
        );
      })}
    </form>
  );
}

FullContactForm.defaultProps = {
  className: "full-contact-form"
};

export default ContactForm;
