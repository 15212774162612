/* eslint-disable */
/**
 * Footer
 * @namespace MainFooter
 */

import React, { Fragment } from "react";
import { Translation } from "react-i18next";

import LanguageSelection from "../components/LanguageSelection";
import ReactImage from "../components/ReactImage";
import { displayMenuItem } from "./functions";
import { nl2br } from "../components/functions";
import { freeTrialUrl } from "../config/home/freeTrial";

import lord from "../assets/img/layout/design-by.png";

import In from "../assets/img/layout/in.svg";
import fb from "../assets/img/layout/fb.svg";
import medium from "../assets/img/layout/medium.svg";
import tw from "../assets/img/layout/tw.svg";
import { onClickFreeTrialButton } from "../components/ContactForm";

/**
 * Social links
 * @name socials
 * @memberof MainFooter
 * @constant
 * @type {Social[]}
 */

const socials = [
  {
    img: {
      src: medium,
      alt: "Medium"
    },
    url: "https://blog.hiresweet.com"
  },
  {
    img: {
      src: fb,
      alt: "facebook"
    },
    url: "https://www.facebook.com/hiresweet/"
  },
  {
    img: {
      src: tw,
      alt: "Twitter"
    },
    url: "https://twitter.com/hiresweet_"
  },
  {
    img: {
      src: In,
      alt: "LinkedIn"
    },
    url: "https://www.linkedin.com/company/hiresweet/"
  }
];

/**
 * SPA footer contains
 * 1. Menu
 * @class
 * @name MainFooter
 * @memberof MainFooter
 * @param {Object} props
 * @param {MenuItem[]} props.menuItems - Footer menu items
 * @return {ReactComponent}
 */
export default function({ menuItems }) {
  return (
    <div className="wrapper">
      <div className="footer-group--header">
        <div className="footer__logo">
          <a className="logo-footer" href="/"></a>
        </div>

        <div className="footer__contact">
          <Translation>
            {t => (
              <Fragment>
                <span className="footer__item footer-subscribe">
                  {nl2br(t("subscribe"))}
                </span>
                <a href={freeTrialUrl} onClick={onClickFreeTrialButton} id='footer-cta'>
                  <button className="hs-button--submit">
                    {t("try_free_trial")}
                  </button>
                </a>
              </Fragment>
            )}
          </Translation>
        </div>
      </div>

      <div className="footer-group--menu">
        <div className="footer__lang">
          <span className="footer__item">
            <Translation>{t => t("language")}</Translation>
          </span>
          <LanguageSelection />
        </div>

        {/* Menu 1/2 */}
        <div>
          <ul className="footer-menu">
            {menuItems.slice(1, 3).map(displayMenuItem)}
          </ul>
        </div>
      </div>

      {/* Menu 1/2 */}
      <ul className="footer-menu footer-group--others">
        {displayMenuItem(menuItems[0])}
        {menuItems.slice(3).map(displayMenuItem)}
      </ul>

      {/* Delimiter */}
      <hr />

      <div className="footer-group--down">
        {/* Copyright */}
        <p className="footer__copyright">
          <Translation>{t => t("copyright")}</Translation>
        </p>

        {/* Design by Lord */}
        <a
          href="https://callmelord.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="design-by"
        >
          <img src={lord} alt="design by Lord" />
        </a>

        {/* Social icons */}
        <ul className="footer__socials">
          {socials.map(({ img, url }, i) => {
            return (
              <li key={i}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <ReactImage img={img} />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
