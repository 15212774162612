/* eslint-disable */
/**
 * Kpi component
 * @namespace Kpi
 */
import React, { Fragment, useState, useLayoutEffect } from "react";
import CountUp from "react-countup";

import { nl2br } from "../functions";

/**
 * Count duration
 * @constant
 * @name COUNT_UP_DURATION
 * @memberof Kpi
 * @type {number}
 */
const COUNT_UP_DURATION = 2;

/**
 * Check is element is in viewport
 * @function isElementInVp
 * @memberof Kpi
 * @param {HTMLElement} Node - Html element
 * @return {boolean}
 */
function isElementInVp(Node) {
  let rect = Node.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
/**
 * Start count up and prevent further rendering
 * @function
 * @name windowHasScrolled
 * @param {HTMLElement} Node - CountUp component
 * @param {Object} state
 * @param {function) state.start - Start function
 * @param {boolean} state.hasRendered - Countup has already fired
 * @param {function} setter - state modifier
 * @return {function} scroll event handler
 */
function windowHasScrolled(Node, state, setter) {
  return evt => {
    if (state.hasRendered) return;
    if (Node.current && isElementInVp(Node.current)) {
      if (typeof state.start == "function") {
        setter({
          start: state.start,
          hasRendered: true
        });
        state.start();
      }
    }
  };
}

/**
 * Display KPI
 * @class
 * @name Kpi
 * @memberof Kpi
 * @param {KPI} props - Kpi meta data
 * @return {ReactComponent}
 */
export default function({ title, content }) {
  let titleArr = title.split(" ");
  let KPI = titleArr.shift();

  let Node = React.createRef();

  const [state, setState] = useState({
    hasRendered: false,
    start: null
  });

  // Scroll event
  useLayoutEffect(() => {
    if (window.DEBUG) {
      console.log("DEBUG: Kpi State HAS CHANGED", state);
    }
    window.addEventListener("scroll", windowHasScrolled(Node, state, setState));
    return () => {
      window.removeEventListener(
        "scroll",
        windowHasScrolled(Node, state, setState)
      );
    };
  });

  return (
    <div ref={Node} className="hs-kpi">
      <h3 className="hs-kpi__title">
        {isNaN(Number(KPI)) ? (
          <span>{title}</span>
        ) : (
          <Fragment>
            <span>
              <CountUp start={0} end={Number(KPI)} duration={COUNT_UP_DURATION}>
                {({ countUpRef, start }) => {
                  // Set start function
                  setState({ hasRendered: false, start: start });

                  return <span ref={countUpRef}></span>;
                }}
              </CountUp>
            </span>
            {titleArr.join(" ")}
          </Fragment>
        )}
      </h3>
      <hr className="hr-sep" />
      <p className="hs-kpi__content">{nl2br(content)}</p>
    </div>
  );
}
