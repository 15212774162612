import React, {Fragment, useState} from 'react';
import { Translation } from 'react-i18next';

import WebApp, { WebAppBox, WebAppBoxSimple } from '../WebApp';

import { nl2br } from '../functions';

import {ReactComponent as ProgressBar} from '../../assets/img/platform/webapp/progress-bar.svg'
import {ReactComponent as File} from '../../assets/img/platform/webapp/file.svg'
import {ReactComponent as FullBar} from '../../assets/img/platform/webapp/full-bar.svg'
import {ReactComponent as Placeholder01} from '../../assets/img/platform/webapp/placeholder01.svg'
import {ReactComponent as Placeholder02} from '../../assets/img/platform/webapp/placeholder02.svg'
import {ReactComponent as Placeholder03} from '../../assets/img/platform/webapp/placeholder04.svg'
import {ReactComponent as Socials} from '../../assets/img/platform/webapp/socials.svg'
import {ReactComponent as SocialsApp} from '../../assets/img/platform/webapp/socials-app.svg'
import {ReactComponent as Skills} from '../../assets/img/platform/webapp/skills.svg'
import {ReactComponent as SalaryBar} from '../../assets/img/platform/webapp/salary-bar.svg' 

import profile01 from '../../assets/img/platform/webapp/profile01.png';
import profile02 from '../../assets/img/platform/webapp/profile02.png';
import profile03 from '../../assets/img/platform/webapp/profile03.png';
import profile04 from '../../assets/img/platform/webapp/profile04.png';
import profile05 from '../../assets/img/platform/webapp/profile05.png';
import profile06 from '../../assets/img/platform/webapp/profile06.png';
//import selectedProfile from '../../assets/img/platform/webapp/selected-profile.png';

import app01 from '../../assets/img/platform/webapp/sqreen.png';
import app02 from '../../assets/img/platform/webapp/lydia.png';
import app03 from '../../assets/img/platform/webapp/drivy.png';
import app04 from '../../assets/img/platform/webapp/blablacar.png';
import app05 from '../../assets/img/platform/webapp/Nokia.svg';

import cpy01 from '../../assets/img/platform/webapp/blablacar2x.png';
import cpy02 from '../../assets/img/platform/webapp/heetch2x.png';
const cpy03 = app05;

const profileImgs = [
  {
		img: profile01,
		name: 'screen_profile_name',
		job: 'screen_profile_job',
	},
	{
		img: profile02,
		name: 'Megan Berry',
		job: 'Software Engineer @ TimeWrap',
	},
	{
		img: profile03,
		name: 'Maria Momeni',
		job: 'Software Engineer @ Tigwi',
	},
	{
		img: profile04,
		name: 'Marc Bonisseur',
		job: 'Software Engineer @ Ogoyapp',
	},
	{
		img: profile05,
		name: 'Jessica Messio',
		job: 'Software Engineer @ Acme',
	},
	{
		img: profile06,
		name: 'Alan Maravan',
		job: 'Software Engineer @ Lapito',
	},
];

const appImgs = [
	app05,
	app01,
	app02,
	app02,
	app03,
	app04,
];

/**
 * Fake webapp screen for talents companies
 * @class
 * @name Companies
 * @memberof WebApp
 */
export function Companies() {
	return (
		<Translation>{
			t => (
				<WebApp theme = 'mixed' headers={[{
					jsx: (<span>{t('screen_relevant_cpy')}</span>)
				}]}>
					<div className = 'webapp__wrapper'>
						<span className = 'webapp-company'>
							<span className = 'webapp-company__img'>
								<figure className = 'img-sqr'>
									<img src = {cpy01} alt = 'company' />
								</figure>
							</span>
							<span className = 'webapp-company__content'>
								<span>{t('screen_cpy_01')}</span>
								<span>{t('screen_cpy_01_content')}</span>
							</span>
							<span className = 'webapp-number webapp-number--75'>75%</span>
						</span>
						<span className = 'webapp-company'>
							<span className = 'webapp-company__img'>
								<figure className = 'img-sqr'>
									<img src = {cpy02} alt = 'company' />
								</figure>
							</span>
							<span className = 'webapp-company__content'>
								<span>{t('screen_cpy_02')}</span>
								<span>{t('screen_cpy_02_content')}</span>
							</span>
							<span className = 'webapp-number webapp-number--71'>71%</span>
						</span>
						<span className = 'webapp-company'>
							<span className = 'webapp-company__img'>
								<figure className = 'img-sqr'>
									<img src = {cpy03} alt = 'company' />
								</figure>
							</span>
							<span className = 'webapp-company__content'>
								<span>{t('screen_cpy_03')}</span>
								<span>{t('screen_cpy_03_content')}</span>
							</span>
							<span className = 'webapp-number webapp-number--68'>68%</span>
						</span>
					</div>
				</WebApp>
			)
		}</Translation>
	)
}

/**
 * Fake webapp screen for talents opportunity
 * @class
 * @name Opportunity
 * @memberof WebApp
 */
export function Opportunity() {
	return (
		<Translation>{
			t => (
				<WebApp theme = 'nohead'>
					<div className = 'webapp__wrapper--gray'>
						<span className = 'webapp-opportunity'>
							<span className = 'webapp-placeholder--square webapp-placeholder--red'></span>
							<span>
								<span className = 'webapp-opportunity__title'>{t('screen_heetch')}</span>
								<span className = 'webapp-opportunity__content'>{t('screen_heetch_content')}</span>
							</span>
						</span>
						<span className = 'webapp-opportunity'>
							<span className = 'webapp-placeholder--square'></span>
							<span>
								<span className = 'webapp-placeholder--rect webapp-placeholder--smaller'></span>
								<span className = 'webapp-placeholder--rect'></span>
							</span>
						</span>
						<span className = 'webapp-opportunity'>
							<span className = 'webapp-placeholder--square'></span>
							<span>
								<span className = 'webapp-placeholder--rect webapp-placeholder--smaller'></span>
								<span className = 'webapp-placeholder--rect'></span>
							</span>
						</span>
						<span className = 'webapp-opportunity'>
							<span className = 'webapp-placeholder--square'></span>
							<span>
								<span className = 'webapp-placeholder--rect webapp-placeholder--smaller'></span>
								<span className = 'webapp-placeholder--rect'></span>
							</span>
						</span>
						<span className = 'webapp-opportunity'>
							<span className = 'webapp-placeholder--square'></span>
							<span>
								<span className = 'webapp-placeholder--rect webapp-placeholder--smaller'></span>
								<span className = 'webapp-placeholder--rect'></span>
							</span>
						</span>
						<span className = 'webapp-opportunity'>
							<span className = 'webapp-placeholder--square'></span>
							<span>
								<span className = 'webapp-placeholder--rect webapp-placeholder--smaller'></span>
								<span className = 'webapp-placeholder--rect'></span>
							</span>
						</span>
					</div>
				</WebApp>
			)
		}</Translation>
	)
}

/**
 * Fake webapp screen for talents corporate
 * @class
 * @name Corporate
 * @memberof WebApp
 */
export function Corporate() {
	return (
		<Translation>{
			t => (
				<WebApp theme = 'regular' headers={[{
					jsx: (
						<ul className = 'webapp-profiles'>
							{ appImgs.map((img,i) => {
								  return (
									  <div key = { i } className = {`webapp-profiles__img ${i===0?'selected':''}`}>
										  <figure className = 'img-sqr'>
											  <img src = { img } alt = 'app profile' />
										  </figure>
									  </div>
								  );
							})}
						</ul>
					),
				}]}>
					<div className = 'webapp__wrapper'>
						<div className = 'webapp-selected'>
							<img src = { app05 } 
									 alt = 'app profile' 
									 className = 'webapp-selected__img'/>
							<span className = 'webapp-selected__title'>
								{t('screen_app_name')}
							</span>
							<span className = 'webapp-selected__job'>
								{t('screen_app_job')}
							</span>
							<span className = 'webapp-selected__socials'>
								<SocialsApp />
							</span>

							<span className = 'webapp-selected__plchldr'>
								<Placeholder03 />
							</span>
						</div>
					</div>
				</WebApp>
			)
		}</Translation>
	)
}


/**
 * Fake webapp screen for talents wishes
 * @class
 * @name Wishes
 * @memberof WebApp
 */
export function Wishes() {
	return (
		<Translation>{
			t => (
				<WebApp theme = 'mixed' headers={[{
					jsx: (<span>{t('screen_wishes')}</span>)
				}]}>
					<div className = 'webapp__wrapper'>
						<div className = 'webapp-wish'>
							
							<div className = 'grid'>
								<div className = 'grid__item col-lg-6 col-sm-6'>
									<span className = 'webapp-wish__title'>
										{t('screen_job')}
									</span>
									<ul className = 'webapp-wish__content'>
										<li className = 'selected'>{t('screen_dev')}</li>
										<li>{t('screen_data_science')}</li>
										<li>{t('screen_product_manager')}</li>
										<li>{t('screen_devops')}</li>
									</ul>
								</div>
								<div className = 'grid__item col-lg-6 col-sm-6'>
									<span className = 'webapp-wish__title'>
										{t('screen_skill')}
									</span>
									<span className = 'webapp-wish__skills webapp-wish__content'>
										<Skills />
									</span>
								</div>
							</div>

							<div className = 'grid'>
								<div className = 'grid__item col-lg-6 col-sm-6'>
									<span className = 'webapp-wish__title'>
										{t('screen_contract')}
									</span>
									<ul className = 'webapp-wish__content'>
										<li className = 'selected'>{t('screen_lg_contract')}</li>
										<li>{t('screen_shrt_contract')}</li>
									</ul>
								</div>
								<div className = 'grid__item col-lg-6 col-sm-6'>
									<span className = 'webapp-wish__title'>
										{t('screen_salary')}
									</span>
									<span className = 'webapp-wish__bar webapp-wish__content'>
										35K<SalaryBar />100K
									</span>
								</div>
							</div>

						</div>
					</div>
				</WebApp>
			)
		}</Translation>
	)
}

/**
 * Fake webapp screen for User Profile
 * @class
 * @name ProfileScreen
 * @memberof WebApp
 */
export function ProfileScreen() {
	const [profileSelected, setProfile] = useState(profileImgs[0]);

	/**
	 * Change selected profile picture
	 * @function changeProfile
	 * @memberOf WebApp
	 * @param  {String} img Selected profile image
	 */
	function changeProfile(img) {
		// onclick event handler
		return (evt) => {
			evt.preventDefault();
			setProfile(img);
		}
	}

	return(
		<Translation>{
			t => (
				<WebApp theme = 'regular' headers={[{
					jsx: (
						<ul className = 'webapp-profiles'>
							{ profileImgs.map(({img, job, name}, i) => {

								  return (
                    <div 
										  key = { i } className = {`webapp-profiles__img ${img===profileSelected.img?'selected':''}`}
										    onClick = {changeProfile({img,job,name})}
                    >
										  <figure className = 'img-sqr'>
											  <img src = { img } alt = 'app profile' />
										  </figure>
									  </div>
								  );
							})}
						</ul>
					)
				}]}>
					<div className = 'webapp__wrapper'>
						<div className = 'webapp-selected'>
              <img src = { profileSelected.img } 
									 alt = 'app profile' 
									 className = 'webapp-selected__img'/>
							<span className = 'webapp-selected__title'>
                {t(profileSelected.name)}
							</span>
							<span className = 'webapp-selected__job'>
                {t(profileSelected.job)}
							</span>
							<span className = 'webapp-selected__socials'>
								<Socials />
							</span>

							<span className = 'webapp-selected__plchldr'>
								<Placeholder03 />
							</span>
						</div>
					</div>
				</WebApp>
			)
		}</Translation>
	); 
}

/**
 * Fake webapp screen for Metrics
 * @class
 * @name Metrics
 * @memberof WebApp
 */
export function Metrics() {
	return (
		<Translation>{
			t => (
				<WebApp headers={[{
					jsx: (
						<div className = 'webapp-pgbar'>
							<span>{t('screen_hire_lvl')}</span>
							<span>52%</span>
							<ProgressBar />
						</div>
					)
				}]}>
					<div className = 'webapp__wrapper'>
						<table className = 'webapp-table'>
							<tbody>
								<tr>
									<td><span>10</span>{t('screen_in_pipe')}</td>
									<td><span>24</span>{t('screen_contacted')}</td>
									<td><span>0</span>{t('screen_answered')}</td>
								</tr>
								<tr>
									<td><span>0</span>{t('screen_in_progress')}</td>
									<td className = 'td-red'>
										<span>-</span>{t('screen_recruited')}
									</td>
									<td><span>18</span>{t('screen_dismissed')}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</WebApp>
			)
		}</Translation>
	)
}

/**
 * Fake webapp screen for Follow Up
 * @class
 * @name FollowUp
 * @memberof WebApp
 */
export function FollowUp() {
	return (
		<Translation>{
			t => (
				<WebApp headers={[{
					jsx: (
						<span className = 'grid-flex grid-flex--row'>
							<span className = 'grid-flex__item--auto'>
								<File />
							</span>
							<span className = 'grid-flex__item--auto'>
								<div className = 'webapp__sm'>{t('screen_intro')}</div>
								<FullBar style = {{marginTop: '6px'}}/>
							</span>
						</span>
					)
				},{
					jsx: (
						<span className = 'grid-flex grid-flex--row'>
							<span className = 'grid-flex__item--auto'>
								<File />
							</span>
							<span className = 'grid-flex__item--auto'>
								<div className = 'webapp__sm'>{t('screen_reach')}</div>
								<FullBar style = {{marginTop: '6px'}}/>
							</span>
						</span>
					)
				}]}>
					<div className = 'webapp__wrapper'>

						<div className = 'grid-flex grid-flex--row'>
							<span className = 'grid-flex__item'>
								<Placeholder01 />
							</span>
							<span className = 'grid-flex__item'>
								<span className = 'webapp-send'>
									{t('screen_send')}
								</span>
							</span>
						</div>

						<div className = 'webapp-templates'>
							<Placeholder02 />
							<span>{t('screen_templates')}</span>
						</div>

						<div className = 'webapp-flwup'>
							<span>{t('screen_send_reply_in')} <b>7 {t('days')}</b></span>
						</div>

						<div className = 'webapp-input'>
							<span>{t('screen_send_reply')}</span>
						</div>
					</div>
				</WebApp>
			)
		}</Translation>
			
	)
}

/**
 * Fake webapp screen for Skills & Profile
 * @class
 * @name SkillAndProfile
 * @memberof WebApp
 */
export function SkillAndProfile() {
	return (
		<Translation>{
			t => (
				<WebApp headers={[{
					jsx: (<span>{t('screen_search')}</span>),
					icon: 'webapp-icon webapp-icon--filter'
				}]}>
					<Fragment>
						<WebAppBox id = 'WebAppProfile'
							title = {t('screen_profile')}>
							{[[
							 		t('screen_title'), 
							 		<span className = 'webapp-bar'></span>
							 	],[
							 		t('screen_type'), 
							 		<span className = 'webapp-bar'></span>
							 	],[
							 		t('screen_xp'), 
							 		<span className = 'webapp-bar'></span>
							 	],[
							 		t('screen_mgmt'), 
							 		<span className = 'webapp-bar'></span>
							 	],[
							 		t('screen_remote'), 
							 		<span className = 'webapp-bar'></span>
							 	]]}
						</WebAppBox>
						<WebAppBox id = 'WebAppSkills'
							title = {t('screen_skills')}>
							{[[
									t('screen_required'),
									<Fragment>
										<span className = 'webapp-bar--red'></span>
										<span className = 'webapp-bar--red'></span>
										<span className = 'webapp-bar--red'></span>
									</Fragment>
								],[
							 		t('screen_important'),
							 		<span className = 'webapp-bar--red'></span>
							 	],[
							 		t('screen_bonus'),
							 		<span className = 'webapp-bar'></span>
							 	]]}
						</WebAppBox>
					</Fragment>
				</WebApp>
			)
		}</Translation>
	)
}

/**
 * Fake webapp screen for FullStack dev
 * @class
 * @name FullStack
 * @memberof WebApp
 */
export function FullStack() {
	return (
		<Translation>{
			t => (
				<WebApp headers={[{
					jsx: (<span>{t('screen_fullstack')}</span>)
				}]}>
					<Fragment>
						<WebAppBoxSimple id = 'WebAppPassed'
							title = {t('screen_passed')}>
							{[
								nl2br(t('screen_fs_line_01')),
							 	nl2br(t('screen_fs_line_02')),
							 	nl2br(t('screen_fs_line_03')),
							 	nl2br(t('screen_fs_line_04')),
							 	nl2br(t('screen_fs_line_05'))
							]}
						</WebAppBoxSimple>
						<WebAppBoxSimple id = 'WebAppVerif'
							title = {t('screen_verif')}>
							{[
								nl2br(t('screen_fs_line_06')),
								nl2br(t('screen_fs_line_07')),
								nl2br(t('screen_fs_line_08')), 
								nl2br(t('screen_fs_line_09'))
							]}
						</WebAppBoxSimple>
					</Fragment>
				</WebApp>
			)
		}</Translation>
	)
}
