/**
 * Meta data for home page
 * @namespace HomeData
 */

import aircall from '../../assets/img/home/brands/aircall.svg';
import blablacar from '../../assets/img/home/brands/blablacar.svg';
import brand from '../../assets/img/home/brands/brand.svg';
import dashlane from '../../assets/img/home/brands/dashlane.svg';
import mindsay from '../../assets/img/home/brands/mindsay.svg';
import drivy from '../../assets/img/home/brands/drivy.svg';
import gorgias from '../../assets/img/home/brands/gorgias.svg';
import ledger from '../../assets/img/home/brands/ledger.svg';
import lydia from '../../assets/img/home/brands/lydia.svg';
import meetic from '../../assets/img/home/brands/meetic.svg';
import meilleurs_agents from '../../assets/img/home/brands/meilleurs-agents.svg';
import nokia from '../../assets/img/home/brands/nokia.svg';
import shapr from '../../assets/img/home/brands/shapr.svg';
import spendesk from '../../assets/img/home/brands/spendesk.svg';
import sqreen from '../../assets/img/home/brands/sqreen.svg';

/**
 * Brand logo
 * @typedef {Object} Brand
 * @property {String} src
 * @property {String} alt
 */

/**
 * Brands / partners
 * @constant
 * @name brands
 * @memberof HomeData
 * @type {Brand[]}
 */ 
export const brands = [{
	src: aircall,
	alt: 'aircall'
},{
	src: blablacar,
	alt: 'blablacar'
},{
	src: brand,
	alt: 'brand'
},{
	src: dashlane,
	alt: 'dashlane'
},{
	src: mindsay,
	alt: 'mindsay'
},{
	src: drivy,
	alt: 'drivy'
},{
	src: gorgias,
	alt: 'gorgias'
},{
	src: ledger,
	alt: 'ledger'
},{
	src: lydia,
	alt: 'lydia'
},{
	src: meetic,
	alt: 'meetic'
},{
	src: meilleurs_agents,
	alt: 'meilleurs-agents'
},{
	src: nokia,
	alt: 'nokia'
},{
	src: shapr,
	alt: 'shapr'
},{
	src: spendesk,
	alt: 'spendesk'
},{
	src: sqreen,
	alt: 'sqreen'
}];


/**
 * Brand slider options
 * @constant
 * @name brandsSettings
 * @memberof HomeData
 * @type {Object}
 */
 export const brandsSettings = {
   arrows: false,
   dots: false,
   infinite: true,
   draggable: false,
   slidesToShow: 1,
   slidesToScroll: 1,
   centerMode: true,
   variableWidth: true,
   autoplay: true,
   speed: 3200,
   autoplaySpeed: 1,
   cssEase: "linear"
 }; 
// export const brandsSettings = {
//   arrows: false,
//   dots: false,
//   infinite: true,
//   draggable: false,
//   slidesToShow: 10,
//   slidesToScroll: 1,
//   autoplay: true,
//   speed: 3200,
//   autoplaySpeed: 1,
//   cssEase: "linear",
//   responsive: [
//   	{
//   	  breakpoint: 1440,
//   	  settings: {
//   	    slidesToShow: 10,
//   	  }
//   	},
//   	{
//   	  breakpoint: 1330,
//   	  settings: {
//   	    slidesToShow: 9,
//   	  }
//   	},
//   	{
//   	  breakpoint: 1220,
//   	  settings: {
//   	    slidesToShow: 8,
//   	  }
//   	},
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 6,
//       }
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 4,
//       }
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 4,
//       }
//     }
//   ]
// };