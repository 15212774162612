/**
 * @typedef {Object} KPI
 * @property {String} title
 * @property {String} content
 */

/**
 * Kpis
 * @constant
 * @name kpis
 * @memberof HomeData
 * @type {KPI[]}
 */ 
export const kpis = [{
	title: 'kpi_01_title',
	content: 'kpi_01_content'
},{
	title: 'kpi_02_title',
	content: 'kpi_02_content'
},{
	title: 'kpi_03_title',
	content: 'kpi_03_content'
}];