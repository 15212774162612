/**
 * Slider for testimonies
 * @namespace TestimonySlider
 */
import React, { useState } from 'react';
import Slider from 'react-slick';
import { Translation } from 'react-i18next';

import ReactImage from '../ReactImage';

/** 
 * Image fade out duration
 * @constant
 * @name ANIMATION_DELAY
 * @memberof TestimonySlider
 * @type {number}
 */
const ANIMATION_DELAY = 500;

/** 
 * Display testimony slide
 * @function
 * @name displayTestimony
 * @memberof TestimonySlider
 * @param {function} t - translation function
 * @param {Testimony} testimony - testimony meta data 
 * @return {ReactComponent} Slide with testimony
 */
function displayTestimony(t, testimony, i) {
	const {name, job, content} = testimony;
	return (
		<div key = { i } >
			<div className = 'home-testimony'>
				{/* Testomony name */}
				<h2 className = 'home-testimony__name'>
					{ t(name) }
				</h2>

				{/* Testomony job description */}
				<span className = 'home-testimony__job'>
					{ t(job) }
				</span>

				{/* Testomony content */}
				<p className = 'home-testimony__content'>
					{ t(content) }
				</p>
			</div>
		</div>
	)
} 

/**
 * Display testimony slider
 * @class
 * @name TestimonySlider
 * @memberof TestimonySlider
 * @param {Object} props
 * @param {Testimony[]} props.testimonies - Testimonies meta data
 * @param {Object} props.settings - Settings for react-slick slider
 * @return {ReactComponent} Slider with testimonies
 */
export default function TestimonySlider({testimonies, settings}) {
	const [currentImg, setImg] = useState(testimonies[0].img);
	const [fadeIn, setFadeIn] = useState(false);

	/**
	 * Change current img on slide change
	 * @function
	 * @name beforeChange
	 * @memberof TestimonySlider
	 * @param {Number} oldIndex - Old slide index
	 * @param {Number} newIndex - New slide index
	 */
	settings.beforeChange = function(oldIndex,newIndex) {
		if (testimonies[newIndex]) {
			// Current img disapear
			setFadeIn(true);

			// New img appear after old one is gone
			setTimeout(() => {
				setImg(testimonies[newIndex].img);
				setFadeIn(false);
			}, ANIMATION_DELAY);
		}
		else setImg({});
	}

	return (
		<div className = 'testimony-slider'>

			{/* Current img */}
			<div>
				<ReactImage img = { currentImg } 
										className = {
											fadeIn ? 
											'testimony-slider__img fadeIn':
											'testimony-slider__img'
										} />
			</div>

			{/* Slider */}
			<div className = 'testimony-slider__slider sliding'>
				<div className = 'testimony-slider__slider-wrapper'>
					<Translation>
						{t => (
							<Slider {...settings}>
								{testimonies.map(displayTestimony.bind(null,t))}
							</Slider>
						)}
					</Translation>
				</div>
			</div>

		</div>
	)
}