import greenhouse from "../../assets/img/home/integrations/greenhouse.svg";
import lever from "../../assets/img/home/integrations/lever.svg";
import recruitee from "../../assets/img/home/integrations/recruitee.svg";
import smartRecruiters from "../../assets/img/home/integrations/smartRecruiters.svg";
import workable from "../../assets/img/home/integrations/workable.svg";
import wttj from "../../assets/img/home/integrations/WTTJ.svg";

export const integrations = [
  {
    img: {
      src: greenhouse,
      alt: "Greenhouse"
    }
  },
  {
    img: {
      src: lever,
      alt: "Lever"
    }
  },
  {
    img: {
      src: recruitee,
      alt: "Recruitee"
    }
  },
  {
    img: {
      src: smartRecruiters,
      alt: "SmartRecruiters"
    }
  },
  {
    img: {
      src: wttj,
      alt: "Welcome to the Jungle"
    }
  },
  {
    img: {
      src: workable,
      alt: "Workable"
    }
  }
];
