import _ from "underscore";
import { Translation } from "react-i18next";
import React from "react";

const List = items => {
  return () => (
    <Translation>
      {t => (
        <ul>
          {_.map(items, (item, i) => {
            return t(item) ? <li key={i}>{t(item)}</li> : null;
          })}
        </ul>
      )}
    </Translation>
  );
};

export default List;
