import React from "react";
import { integrations } from "../../config/home/integrations";
import ReactImage from "../ReactImage";

const Integrations = () => {
  return (
    <div className="home-integrations__logos">
      {integrations.map((integration, index) => {
        return (
          <div key={index} className='integrations-logo'>
            <ReactImage img={integration.img} />
          </div>
        );
      })}
    </div>
  );
};

export default Integrations;
