/**
 * Home page
 * @namespace PlatformView
 */

import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';

import { platformSections } from '../config/platform/sections';
import { testimonies, testimoniesSettings } from '../config/home/testimonies';

import TestimonySlider from '../components/home/TestimonySlider';
import HomeSection from '../components/home/HomeSection';

import { nl2br } from '../components/functions';

/**
 * Page platform
 * @class
 * @name PlatformView
 * @memberof PlatformView
 * @return {ReactComponent}
 */
export default function() {
	const platformHeader = platformSections[0];

	useEffect(() => {
		window.scrollTo({top:0});
	},[]);
	
	return (
		<Translation>
			{
				t => (
					<div id = 'PlatformView'>

						<header id = 'PlatformHeader' className = 'corner'>

							<div className = 'wrapper'>
								<h1 className = 'platform-title push-lg-1 push-md-1'>
									{ nl2br(t('platform_title')) }
								</h1>
							</div>
							<div id = 'PlatformSoftBg01' className = 'soft-bg'>
								<HomeSection id = { platformHeader.id }
														 title = { t(platformHeader.title) }
														 content = { nl2br(t(platformHeader.content)) }
														 gridLeft = { platformHeader.gridLeft }
														 gridRight = { platformHeader.gridRight }
														 className = 'section-platform'
														 ChildNode = { platformHeader.ChildNode } />
							</div>
						</header>

						{ platformSections
								.slice(1)
								.map((props,i) => {
									// Translate content
									const title = t(props.title);
									const content = nl2br(t(props.content));
									let button = null;
									if (props.button){ 
										button = {
											...props.button,
											text: t(props.button.text),
										}}

									return (
										<div key = { props.id } 
												 id = {`PlatformSoftBg0${i+2}`} 
												 className = 'soft-bg'>
											<HomeSection
												className = 'section-platform'
												title={title}
												content={content}
												button={button}
												id={props.id}
												subtitle={props.subtitle}
												img={props.img}
												ChildNode={props.ChildNode}
												gridLeft={props.gridLeft}
												gridRight={props.gridRight}
												/>
										</div>
									)
								})
						}


						{/* Testomony */}
						<section id = 'HomeTestimony'>
							<TestimonySlider testimonies = { testimonies }
															 settings = { testimoniesSettings } />
						</section>

					</div>
				)
			}
		</Translation>
	)
}