/**
 * Home page
 * @namespace NotFoundView
 */

import React from "react";
import { Translation } from "react-i18next";


/**
 * NotFound page
 * @class
 * @name NotFoundView
 * @memberof NotFoundView
 * @return {ReactComponent}
 */
export default function() {
  return (
    <Translation>
      {t => (
        <div id="SecurityView" className="corner">
        <header id="SecurityHeader">
          <div className="wrapper">
            <h1 className="security-title push-lg-1 push-md-1">
              {(t("not_found"))}
            </h1>
          </div>
        </header>
        </div>
      )}
    </Translation>
  );
}
