/**
 * Home page
 * @namespace ContactView
 */

import React, { useEffect } from "react";
import { Translation } from "react-i18next";

import { FullContactForm } from "../components/ContactForm";

import { contactInputs } from "../config/forms/inputs";
import { nl2br, getQueryComponent } from "../components/functions";

/**
 * Contact page
 * @class
 * @name ContactView
 * @memberof ContactView
 * @return {ReactComponent}
 */
export default function() {
  const email = getQueryComponent("email");
  let defaultInputs = contactInputs;
  if (email) defaultInputs[0].value = email;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);


  return (
    <Translation>
      {t => (
        <div id="ContactView" className="contact">
          <div className="wrapper">
            <div className="grid-flex grid-flex--center">
              <div className="grid-flex__item col-lg-5 col-md-5 push-lg-1 push-md-1">
                <header id="ContactViewHeader" className="section-platform">
                  <div className="section-platform__wrapper">
                    <h1 className="contact__title home-title">
                      {nl2br(t("contact_title"))}
                    </h1>
                    <p className="contact__content">
                      {nl2br(t("contact_content"))}
                    </p>
                    <p className="contact__info">{nl2br(t("contact_info"))}</p>
                  </div>
                </header>
              </div>

              <div className="grid-flex__item col-lg-5 col-md-5 push-lg-2 push-md-2">
                <FullContactForm
                  id="FullContactForm"
                  inputs={defaultInputs.map(function(input) {
                    return {
                      ...input,
                      placeholder: t(input.placeholder),
                      options: [],
                      ...input.options && {
                        options: input.options.map(option => ({
                          ...option,
                          label: t(option.label)
                        }))
                      }
                    };
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
}
