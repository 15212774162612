import bfmBW from '../../assets/img/home/press/bfmBW.svg';
import forbesBW from '../../assets/img/home/press/forbesBW.svg';
import frenchWebBW from '../../assets/img/home/press/frenchWebBW.svg';
import lesEchosBW from '../../assets/img/home/press/lesEchosBW.svg';
import techCrunchBW from '../../assets/img/home/press/techCrunchBW.svg';

/**
 * Press logo and link
 * @typedef {Object} Press
 * @property {Image} img - Press logo
 * @property {string} url - Article url
 */

/**
 * Press reviews
 * @constant
 * @name press
 * @memberof HomeData
 * @type {Press[]}
 */ 
export const press = [{
	img: {
		src: forbesBW,
		alt: 'Forbes'
	},
	url: 'https://www.forbes.fr/technologie/le-recrutement-par-lintelligence-artificielle'
},{
	img: {
		src: lesEchosBW,
		alt: 'Les Echos'
	},
	url: 'https://business.lesechos.fr/directions-numeriques/digital/big-data/030841797805-l-intelligence-artificielle-pour-chasser-les-talents-de-la-tech-315610.php'
},{
	img: {
		src: frenchWebBW,
		alt: 'French Web'
	},
	url: 'https://www.frenchweb.fr/hiresweet-15-million-deuros-pour-denicher-les-meilleurs-developpeurs/30671'
},{
	img: {
		src: bfmBW,
		alt: 'BFM'
	},
	url: 'https://www.youtube.com/watch?v=VyMESxgtNS0'
},{
	img: {
		src: techCrunchBW,
		alt: 'Tech Crunch'
	},
	url: 'https://techcrunch.com/2017/10/24/hiresweet-monitors-github-and-stack-overflow-to-recommend-you-your-next-engineer'
}];
