/**
 * Home page
 * @namespace TechnologyView
 */

import React, {useEffect} from 'react';
import { Translation } from 'react-i18next';

import { technologySections } from '../config/technology/sections';
import { testimonies, testimoniesSettings } from '../config/home/testimonies';

import ReactImage from '../components/ReactImage';
import TestimonySlider from '../components/home/TestimonySlider';
import HomeSection from '../components/home/HomeSection';

import { nl2br } from '../components/functions';


/**
 * Display talent section
 * @function
 * @name displaySection
 * @memberof TalentsView
 * @param {HomeSection} props - section data
 * @param {Nubmer} i - index
 */
function displaySection(t, props, i) {
	const title = props.title ? t(props.title) : null;
  const subtitle = props.subtitle ? t(props.subtitle) : null;
  const content = nl2br(t(props.content));
  let button = null;
  if (props.button) {
    button = {
      ...props.button,
      text: t(props.button.text)
    };
  }
	return (
		<div id = {`${props.id}Bg`} key = {props.id} className = 'soft-bg'>
			<HomeSection
      key={props.id}
      className="section-platform"
      title={title}
      content={content}
      id={props.id}
      subtitle={subtitle}
      button={button}
      img={props.img}
      ChildNode={props.ChildNode}
      gridLeft={props.gridLeft}
      gridRight={props.gridRight}
    />
		</div>
	)
}

const headerSection = technologySections[0];

/**
 * Page technology
 * @class
 * @name TechnologyView
 * @memberof TechnologyView
 * @return {ReactComponent}
 */
export default function() {

	useEffect(() => {
		window.scrollTo({top:0});
	},[]);

	return (
		<Translation>
			{
				t => (
					<div id = 'TechnologyView'>

						{/* Header */}
						<header id = 'TechnologyHeader' className = 'corner'>

							<div className = 'wrapper'>
								<h1 className = 'platform-title push-lg-1 push-md-1'>
									{ nl2br(t('technology_title')) }
								</h1>
							</div>

							<section id = { headerSection.id }>
								<div className = 'wrapper'>
									<div className = 'section-platform grid-flex grid-flex--center'>
										<div className = {`grid-flex__item ${headerSection.gridLeft}`}>
											<h2 className = 'section-platform__title home-title'>
												{nl2br(t(headerSection.title))}
											</h2>
											<p className = 'section-platform__content'>
												{ nl2br(t(headerSection.content)) }
											</p>
											<ul className = 'hs-pilars'>
												{new Array(5).fill(null).map((_,i) => {
													return <li key = { i }>{t(`technology_pilar_0${i+1}`)}</li>
												})}
											</ul>
										</div>
										<div className = {`grid-flex__item ${headerSection.gridRight}`}>
											<ReactImage img = { headerSection.img } 
																	className = 'section-platform__img'/>
										</div>
									</div>
								</div>
							</section>

						</header>

						{ technologySections
								.slice(1)
								.map(displaySection.bind(null,t))
						}

						
						{/* Testomony */}
						<section id = 'HomeTestimony'>
							<TestimonySlider testimonies = { testimonies }
															 settings = { testimoniesSettings } />
						</section>

					</div>
				)
			}
		</Translation>
	)
}