import React from "react";
import { Link } from "react-router-dom";

const URL = /^(https|http):\/\//;

const isExternalLink = url => {
  return URL.test(url);
};

const AdaptableLink = ({ to, children, ...props }) => {
  if (isExternalLink(to)) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }
};

export default AdaptableLink;
