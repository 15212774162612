/* eslint-disable */
/**
 * Image component for React
 * @namespace ReactImage
 */
import React, {useState} from 'react';

/**
 * Set image type based on  aspect ratio
 * @memberof ReactImage
 * @param {function} setter - state modifier
 * @return {function} onload event handler
 */
function setImageType(setter) {
	return (evt) => {
		const { offsetHeight, offsetWidth } = evt.target;
		if (offsetWidth > offsetHeight) {
			setter('landscape');
		} else if (offsetWidth < offsetHeight) {
			setter('portrait');
		} else {
			setter('square');
		}
	}
}

/**
 * Display image with wrappers
 * and set its class based on aspect ratio
 * portrait, landscape, square
 * @typedef {ReactComponent} ReactImage
 * @class
 * @name ReactImage
 * @memberof ReactImage
 * @param {Object} props
 * @param {Image} props.img - Image meta data
 * @param {string} props.className - Image html class
 * @return {ReactImage} React image component
 */
export default function({img, className}) {
	const {src, alt} = img;
	const [imageType, setType] = useState('');

	className = className || '';

	return (
		<div className = { className }>
			<figure>
				<img src = { src } 
						 alt = { alt } 
						 onLoad = { setImageType(setType) }
						 className = { imageType } />
			</figure>
		</div>
	)
}
