/** 
 * @version 1.0
 * @author Pavelcorp
 * @copyright 2019
 */

import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './App.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

import {
	onDomContentLoaded,
	getLocale
} from './functions';

import './scss/stylesheet.css';

import { i18nInit, LangContext } from './config/languages/i18n.js';

// Set DEBUG for development
if (process.env.NODE_ENV === "development") window.DEBUG = 1;

const locale = getLocale();
const DOMLoader = onDomContentLoaded();

if (window.DEBUG) console.log('DEBUG: locale', locale);

// Init translation module
const siteLang = i18nInit(locale);

// Renders component after DOM has loaded
DOMLoader(() => {
	if (window.DEBUG) {
		console.log('DEBUG: DOM has loaded');
		console.log('DEBUG: Rendering App on <root> ...');
	}

	ReactDOM.render(
		<LangContext.Provider value = { siteLang }>
			<App />
		</LangContext.Provider>, 
		document.getElementById('root')
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
