/**
 * Meta data for privacy page
 * @namespace PrivacyData
 */

import ListComponent from "../../components/List";

/**
 * Privacy sections
 * @constant
 * @name privacySections
 * @memberof PrivacyData
 * @type {HomeSection[]}
 */

export const privacySections = [
  {
    id: "PrivacySection00",
    title: "",
    content: "privacy_section_00_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "PrivacySection01",
    title: "privacy_section_01_title",
    content: "privacy_section_01_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: "push-md-1 col-md-10 push-lg-1 col-lg-10 ",
    ChildNode: ListComponent([
      "privacy_section_01_item01",
      "privacy_section_01_item02",
      "privacy_section_01_item03",
      "privacy_section_01_item04"
    ])
  },
  {
    id: "PrivacySection01_2",
    content: "privacy_section_01_2_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "PrivacySection02",
    title: "privacy_section_02_title",
    content: "privacy_section_02_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "PrivacySection03",
    title: "privacy_section_03_title",
    content: "privacy_section_03_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "PrivacySection04",
    title: "privacy_section_04_title",
    content: "privacy_section_04_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "PrivacySection05",
    title: "privacy_section_05_title",
    content: "privacy_section_05_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "PrivacySection06",
    title: "privacy_section_06_title",
    content: "privacy_section_06_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "PrivacySection07",
    title: "privacy_section_07_title",
    content: "privacy_section_07_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  }
];
