/**
 * Meta data for security page
 * @namespace SecurityData
 */

import ListComponent from "../../components/List";

/**
 * Security sections
 * @constant
 * @name securitySections
 * @memberof SecurityData
 * @type {HomeSection[]}
 */

export const securitySections = [
  {
    id: "SecuritySection01",
    title: "security_section_01_title",
    content: "security_section_01_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "SecuritySection02",
    title: "security_section_02_title",
    content: "security_section_02_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "SecuritySection03",
    title: "security_section_03_title",
    content: "security_section_03_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "SecuritySection04",
    title: "security_section_04_title",
    content: "security_section_04_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: "push-md-1 col-md-10 push-lg-1 col-lg-10 ",
    ChildNode: ListComponent([
      "security_section_04_item01",
      "security_section_04_item02",
      "security_section_04_item03",
      "security_section_04_item04",
      "security_section_04_item05",
      "security_section_04_item06",
      "security_section_04_item07"
    ])
  },
  {
    id: "SecuritySection04Bis",
    content: "security_section_04_2_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  },
  {
    id: "SecuritySection05",
    title: "security_section_05_title",
    content: "security_section_05_content",
    gridLeft: "push-md-1 col-md-10 push-lg-1 col-lg-10",
    gridRight: ""
  }
];
